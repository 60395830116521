// import { Link, navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
// import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
// import { getUser, makeLogin } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
// import Button from "../components/Elements/Button"
// import ButtonLite from "../components/Elements/ButtonLite"
// import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import { ClerkProvider, SignIn, useAuth } from "@clerk/clerk-react"
// import axios from "axios"

// const PUBLISHABLE_KEY = "pk_test_dW5pcXVlLWVhcndpZy02NC5jbGVyay5hY2NvdW50cy5kZXYk"

const Login = ({ location }) => {
  const { getToken, isSignedIn } = useAuth();
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication, runLogin } = useContext(
    AuthenticationContext
  )
  console.log(isSignedIn, 'isSignedIn');
  const handleTokenSend = async (token) => {
    console.log("Sending token to the backend...");
    try {
      const response = await fetch(`${process.env.GATSBY_API_BASE}/api/azure`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Send token in Authorization header
          "X-CSRF-Token": state.token,
        },
        credentials: "include",
        // headers: {
        // "Content-Type": "application/json",

        // },
        // withCredentials: true,
      });
      console.log(response, 'response');
      if (response.status === 200) {
        console.log("Token sent successfully!");
        // window.location.href = "/";
        return response;
      } else {
        const errorData = await response.json();
        console.error("Failed to send token:", errorData.error || response.statusText);
        return response;
      }
    } catch (error) {
      console.error("Error sending token:", error.message);
    }
  };
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getToken();
        console.log(token);
      } catch (error) {
        console.error("Error fetching token", error);
      }
    };

    fetchToken();
  }, [getToken]);

  useEffect(async () => {
    if (isSignedIn) {
      const sendToken = async () => {
        const token = await getToken();
        if (token) {
          const check = await handleTokenSend(token);
          console.log(check, 'check');
          if (check.status === 200) {
            console.log('redirecting');
            window.location.href = "/";
          } else {
            window.location.href = "/login_failed";
          }
        }

      };

      sendToken();
    }

  }, [isSignedIn, getToken]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [notMatch, setNotMatch] = useState(false)
  const [loading, setLoading] = useState(false)

  if (loading && isSignedIn)
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
      </div>
    )
  return (
    <>
      {
        !isSignedIn && (
          <LayoutMinimal
            title={"Login to your Account"}
            footer={"©2025 O2O. All rights reserved."}
            metaTitle={"Login to your O2O account"}
          >

            <div className="border border-t-1 border-l-0 border-r-0 border-b-0 border-[#EBEBEB] mt-[30px] mb-[30px]">

            </div>
          </LayoutMinimal>

        )}
    </>
  )

}

export default Login
